<template>
  <div>
    <div v-if="!widthInput" @click="disable ?'' :dialogTableVisible=true">
      <slot></slot>
    </div>
    <div v-if="widthInput" class="flex-def" style="width: 100%">
      <el-input :value=" showValue.id > 0 ? `#${showValue.id}:${showValue.nickname}` : ''" disabled></el-input>
      <el-button @click="dialogTableVisible=true">选择用户</el-button>
    </div>
    <el-dialog append-to-body title="选择用户" :visible.sync="dialogTableVisible">
      <div class="flex-def">
        <el-input style="width: 10rem" v-model="searchForm.nickname" placeholder="输入用户昵称搜索"></el-input>
        <el-button-group style="margin-left: 1rem">
          <el-button @click="searchGo">搜索</el-button>
          <el-button @click="searchReset">全部</el-button>
        </el-button-group>
      </div>
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="choose(s.row)">选中</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "YUserChoose",
  model: {
    prop: "modelVal",
    event: 'valChange'
  },
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    needNormal: {
      type: Boolean,
      default: false
    },
    needCardMember: {
      type: Boolean,
      default: false
    },
    needCardMerchant: {
      type: Boolean,
      default: false
    },
    widthInput: {
      type: Boolean,
      default: false
    },
    modelVal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      page: 1,
      searchForm: {
        nickname: "",
        phone: "",
      },
      list: [],
      total: 0,
      showValue: {
        id: 0,
        nickname: "",
      },
      value: 0,
      loading: false,
    }
  },
  watch: {
    modelVal(now, old) {
      if (old === 0 && now > 0) {
        this.$u.api.user.one({id: now}).then(res => {
          this.showValue = res;
        })
      }
      this.value = this.modelVal;
    },
    value(e) {
      this.$emit('valChange', e);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    choose(e) {
      this.showValue = e;
      this.value = e.id;
      this.dialogTableVisible = false;
      this.$emit("change", e);
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    searchReset() {
      this.searchForm = {
        nickname: "",
        phone: "",
      }
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.load();
    },
    load() {
      this.loading = true;
      this.$u.api.user.search({
        page: this.page,
        nickname: this.searchForm.nickname,
        phone: this.searchForm.phone,
        need_normal: this.needNormal,
        need_card_member: this.needCardMember,
        need_card_merchant: this.needCardMerchant,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>